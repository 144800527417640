import dynamic from 'next/dynamic'

import { useConditionalRendering } from '@/hooks/useConditionalRendering'

import { Hero, Solutions } from '@/sections'

import { If, Banner, BannerButtons } from '@/components'

import { usePageStructure } from './usePageStructure'

const Paywall = dynamic(() =>
  import('@/components/Paywall/Paywall').then((mod) => mod.Paywall)
)

// const Consultation = dynamic(() =>
//   import('@/sections/Consultation/Consultation').then((mod) => mod.Consultation)
// )

const Contact = dynamic(() =>
  import('@/sections/Contact/Contact').then((mod) => mod.Contact)
)

const Help = dynamic(() =>
  import('@/sections/Help/Help').then((mod) => mod.Help)
)

const Hiring = dynamic(() =>
  import('@/sections/Hiring/Hiring').then((mod) => mod.Hiring)
)

const Plans = dynamic(() =>
  import('@/sections/Plans/Plans').then((mod) => mod.Plans)
)

const PersonalSolutions = dynamic(() =>
  import('../../sections/PersonalSolutions/PersonalSolutions').then(
    (mod) => mod.PersonalSolutions
  )
)

const Payment = dynamic(() =>
  import('../../sections/Payment/Payment').then((mod) => mod.Payment)
)

const PostpaidAdvantages = dynamic(() =>
  import('../../sections/PostpaidAdvantages/PostpaidAdvantages').then(
    (mod) => mod.PostpaidAdvantages
  )
)

export const PageStructure = () => {
  const { buildType, lazyLoad, isMobile, isPaywallOpen } = usePageStructure()
  const { renderedContentType } = useConditionalRendering()

  return (
    <main>
      {isPaywallOpen && <Paywall />}

      <Banner
        isAnimated
        align="center"
        text="A Boa Vista mudou e agora é Equifax BoaVista!"
      />

      <Hero variant={buildType} />

      {!isMobile && <Plans />}

      <If condition={lazyLoad}>
        {isMobile && <Plans />}

        <Solutions
          tagText="Soluções para Empresas"
          title={
            <>
              A Equifax BoaVista é um bureau de crédito que oferece soluções e
              informações para <b>analisar o comportamento de pagamento</b> de
              pessoas e empresas.
            </>
          }
        />
        <PostpaidAdvantages />
        <Hiring />
        <Payment />
        {/* <Consultation /> */}
        <PersonalSolutions />
        <Help />
        <Contact />
      </If>

      <If condition={renderedContentType === 'default'}>
        <Banner
          isFixed
          hasBorder
          hideTextOnMobile
          text="Central de vendas Equifax BoaVista"
        >
          <BannerButtons />
        </Banner>
      </If>
    </main>
  )
}
